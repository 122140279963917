import _tonalArray from "tonal-array";
import _tonalNote from "tonal-note";
import _tonalInterval from "tonal-interval";
import _tonalDistance from "tonal-distance";
import _tonalDictionary from "tonal-dictionary";
import _tonalScale from "tonal-scale";
import _tonalChord from "tonal-chord";
import _tonalPcset from "tonal-pcset";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tonalArray = _tonalArray;
var Note = _tonalNote;
var tonalInterval = _tonalInterval;
var Distance = _tonalDistance;
var Dictionary = _tonalDictionary;
var tonalScale = _tonalScale;
var tonalChord = _tonalChord;
var tonalPcset = _tonalPcset;
/**
 * [![npm version](https://img.shields.io/npm/v/tonal-key.svg?style=flat-square)](https://www.npmjs.com/package/tonal-key)
 *
 * The `Tonal` module is a facade to the rest of the modules. They are namespaced,
 * so for example to use `pc` function from `tonal-note` you have to write:
 * `Tonal.Note.pc`
 *
 * It exports the following modules:
 * - Note
 * - Interval
 * - Distance
 * - Scale
 * - Chord
 * - PcSet
 *
 * Additionally this facade exports some functions without namespace (see "Methods" below)
 *
 * @example
 * // es6 modules
 * import * as Tonal from "tonal"
 * Tonal.Note.name("cx") // => "C##"
 *
 * @example
 * import { Note } from "tonal"
 * Note.name("bb") // => "Bb"
 *
 * @example
 * // es5 node modules
 * var Tonal = require("tonal");
 * Tonal.Distance.transpose(Tonal.Note.pc("C#2"), "M3") // => "E#"
 * Tonal.Chord.notes("Dmaj7") // => ["D", "F#", "A", "C#"]
 *
 * @module Tonal
 */

/**
 * Transpose a note by an interval
 * @function
 * @param {string} note
 * @param {string} interval
 * @return {string} the transported note
 * @see Distance.transpose
 */

var transpose = Distance.transpose;
/**
 * Get the interval from two notes
 * @function
 * @param {string} from
 * @param {string} to
 * @return {string} the interval in reverse shorthand notation
 * @see Distance.interval
 */

var interval = Distance.interval;
/**
 * Get note properties
 * @function
 * @param {string} note - the note name
 * @return {Object}
 * @see Note.props
 * @example
 * Tonal.note("A4").chroma // => 9
 */

var note = Note.props;
/**
 * Get midi note number
 * @function
 * @param {string} note
 * @return {Number}
 * @see Note.midi
 * @example
 * Tonal.midi("A4") // => 49
 */

var midi = Note.midi;
/**
 * Get note frequency using equal tempered tuning at 440
 * @function
 * @param {string} note
 * @return {Number}
 * @see Note.freq
 * @example
 * Tonal.freq("A4") // => 440
 */

var freq = Note.freq;
/**
 * Get intervals from a chord type
 * @function
 * @param {string} type - the chord type (no tonic)
 * @return {Array} an array of intervals or undefined if the chord type is not known
 * @see Dictionary.chord
 * @example
 * Tonal.chord("m7b5") // => ["1P", "3m", "5d", "7m"]
 */

var chord = Dictionary.chord;
/**
 * Get intervals from scale name
 * @function
 * @param {string} name - the scale name (without tonic)
 * @return {Array} an array of intervals or undefiend if the scale is not kown
 * @example
 * Tonal.scale("major") // => ["1P", "2M", "3M"...]
 */

var scale = Dictionary.scale;
exports.Array = tonalArray;
exports.Note = Note;
exports.Interval = tonalInterval;
exports.Distance = Distance;
exports.Dictionary = Dictionary;
exports.Scale = tonalScale;
exports.Chord = tonalChord;
exports.PcSet = tonalPcset;
exports.transpose = transpose;
exports.interval = interval;
exports.note = note;
exports.midi = midi;
exports.freq = freq;
exports.chord = chord;
exports.scale = scale;
export default exports;
export const __esModule = exports.__esModule,
      Interval = exports.Interval,
      Scale = exports.Scale,
      Chord = exports.Chord,
      PcSet = exports.PcSet;
const _Array = exports.Array,
      _Note = exports.Note,
      _Distance = exports.Distance,
      _Dictionary = exports.Dictionary,
      _transpose = exports.transpose,
      _interval = exports.interval,
      _note = exports.note,
      _midi = exports.midi,
      _freq = exports.freq,
      _chord = exports.chord,
      _scale = exports.scale;
export { _Array as Array, _Note as Note, _Distance as Distance, _Dictionary as Dictionary, _transpose as transpose, _interval as interval, _note as note, _midi as midi, _freq as freq, _chord as chord, _scale as scale };